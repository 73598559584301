// Suggestion:  Define and work with actual options (e.g. {key, value} stored server side)
// ========== !! IMPORTANT NOTE !! ===============
// The fields are stored as strings in the database.  Make sure you update existing values correctly if modifying the strings below
export interface StringMap {
  [propName: string]: string
}

export enum Continent {
  Africa = 'Africa',
  Antarctica = 'Antarctica',
  Asia = 'Asia',
  Europe = 'Europe',
  North_America = 'North America',
  Oceania = 'Oceania',
  South_America = 'South America',
}

export enum ContinentCode {
  Africa = 'AF',
  Antarctica = 'AN',
  Asia = 'AS',
  Europe = 'EU',
  North_America = 'NA',
  Oceania = 'OC',
  South_America = 'SA',
}

export const ContinentCodeByName = {
  [Continent.Africa]: ContinentCode.Africa,
  [Continent.Antarctica]: ContinentCode.Antarctica,
  [Continent.Asia]: ContinentCode.Asia,
  [Continent.Europe]: ContinentCode.Europe,
  [Continent.North_America]: ContinentCode.North_America,
  [Continent.Oceania]: ContinentCode.Oceania,
  [Continent.South_America]: ContinentCode.South_America,
}

export const countries: StringMap[] = [
  { '': '' },
  { Canada: 'CA' },
  { 'United States': 'US' },
  { Albania: 'AL' },
  { Argentina: 'AR' },
  { Australia: 'AU' },
  { Austria: 'AT' },
  { Belarus: 'BY' },
  { Belgium: 'BE' },
  { Bermuda: 'BM' },
  { Bahamas: 'BS' },
  { Brazil: 'BR' },
  { Brunei: 'BN' },
  { Bulgaria: 'BG' },
  { Chile: 'CL' },
  { China: 'CN' },
  { Colombia: 'CO' },
  { 'Costa Rica': 'CR' },
  { Croatia: 'HR' },
  { Cyprus: 'CY' },
  { 'Czech Republic': 'CZ' },
  { Denmark: 'DK' },
  { 'Dominican Republic': 'DO' },
  { Egypt: 'EG' },
  { Estonia: 'EE' },
  { Ethiopia: 'ET' },
  { Finland: 'FI' },
  { France: 'FR' },
  { Georgia: 'GE' },
  { Guatemala: 'GT' },
  { Germany: 'DE' },
  { Ghana: 'GH' },
  { Greece: 'GR' },
  { Guam: 'GU' },
  { 'Hong Kong': 'HK' },
  { Hungary: 'HU' },
  { Iceland: 'IS' },
  { India: 'IN' },
  { Indonesia: 'ID' },
  { Iraq: 'IQ' },
  { Ireland: 'IE' },
  { Israel: 'IL' },
  { Italy: 'IT' },
  { Japan: 'JP' },
  { Jordan: 'JO' },
  { Kuwait: 'KW' },
  { Latvia: 'LV' },
  { Lebanon: 'LB' },
  { Liechtenstein: 'LI' },
  { Lithuania: 'LT' },
  { Luxembourg: 'LU' },
  { Kenya: 'KE' },
  { Macedonia: 'MK' },
  { Malawi: 'MW' },
  { Malaysia: 'MY' },
  { Mali: 'ML' },
  { Malta: 'MT' },
  { Mauritania: 'MR' },
  { Mexico: 'MX' },
  { Moldova: 'MD' },
  { Monaco: 'MC' },
  { Morocco: 'MA' },
  { Myanmar: 'MM' },
  { Netherlands: 'NL' },
  { 'New Zealand': 'NZ' },
  { Norway: 'NO' },
  { Oman: 'OM' },
  { Pakistan: 'PK' },
  { Panama: 'PA' },
  { Peru: 'PE' },
  { Philippines: 'PH' },
  { Poland: 'PL' },
  { Portugal: 'PT' },
  { 'Puerto Rico': 'PR' },
  { Qatar: 'QA' },
  { Romania: 'RO' },
  { Russia: 'RU' },
  { 'San Marino': 'SM' },
  { 'Saudi Arabia': 'SA' },
  { Senegal: 'SN' },
  { Serbia: 'RS' },
  { Singapore: 'SG' },
  { Slovakia: 'SK' },
  { Slovenia: 'SI' },
  { 'South Africa': 'ZA' },
  { 'South Korea': 'KR' },
  { Spain: 'ES' },
  { Sweden: 'SE' },
  { Switzerland: 'CH' },
  { Taiwan: 'TW' },
  { Tanzania: 'TZ' },
  { Thailand: 'TH' },
  { Turkey: 'TR' },
  { Uganda: 'UG' },
  { Ukraine: 'UA' },
  { 'United Arab Emirates': 'AE' },
  { 'United Kingdom': 'GB' },
  { Uruguay: 'UY' },
  { 'Vatican City': 'VA' },
  { Vietnam: 'VN' },
]

export const CA_states: StringMap[] = [
  { Alberta: 'AB' },
  { 'British Columbia': 'BC' },
  { Manitoba: 'MB' },
  { Quebec: 'QC' },
  { Ontario: 'ON' },
  { 'New Brunswick': 'NB' },
  { 'Newfoundland and Labrador': 'NL' },
  { 'Nova Scotia': 'NS' },
  { 'Northwest Territories': 'NT' },
  { Nunavut: 'NU' },
  { 'Prince Edward Island': 'PE' },
  { Saskatchewan: 'SK' },
  { Yukon: 'YT' },
]

export const US_states: StringMap[] = [
  { Alabama: 'AL' },
  { Alaska: 'AK' },
  { Arizona: 'AZ' },
  { Arkansas: 'AR' },
  { California: 'CA' },
  { Colorado: 'CO' },
  { Connecticut: 'CT' },
  {'District of Columbia': 'DC' },
  { Delaware: 'DE' },
  { Florida: 'FL' },
  { Georgia: 'GA' },
  { Hawaii: 'HI' },
  { Idaho: 'ID' },
  { Illinois: 'IL' },
  { Indiana: 'IN' },
  { Iowa: 'IA' },
  { Kansas: 'KS' },
  { Kentucky: 'KY' },
  { Louisiana: 'LA' },
  { Maine: 'ME' },
  { Maryland: 'MD' },
  { Massachusetts: 'MA' },
  { Michigan: 'MI' },
  { Minnesota: 'MN' },
  { Mississippi: 'MS' },
  { Missouri: 'MO' },
  { Montana: 'MT' },
  { Nebraska: 'NE' },
  { Nevada: 'NV' },
  { 'New Hampshire': 'NH' },
  { 'New Jersey': 'NJ' },
  { 'New Mexico': 'NM' },
  { 'New York': 'NY' },
  { 'North Carolina': 'NC' },
  { 'North Dakota': 'ND' },
  { Ohio: 'OH' },
  { Oklahoma: 'OK' },
  { Oregon: 'OR' },
  { Pennsylvania: 'PA' },
  { 'Rhode Island': 'RI' },
  { 'South Carolina': 'SC' },
  { 'South Dakota': 'SD' },
  { Tennessee: 'TN' },
  { Texas: 'TX' },
  { Utah: 'UT' },
  { Vermont: 'VT' },
  { Virginia: 'VA' },
  { Washington: 'WA' },
  { 'West Virginia': 'WV' },
  { Wisconsin: 'WI' },
  { Wyoming: 'WY' },
]

const reduceToNameCode = (objectArray: StringMap[]): StringMap =>
  objectArray.reduce((red, array) => {
    const [name, code] = Object.entries(array)[0]
    red[name] = code
    return red
  }, {})

const reduceToCodeName = (objectArray: StringMap[]): StringMap =>
  objectArray.reduce((red, array) => {
    const [name, code] = Object.entries(array)[0]
    red[code] = name
    return red
  }, {})

export const COUNTRY_NAMES = countries.map(country => Object.keys(country)[0])
export const US_STATE_NAMES = ['', ...US_states.map(state => Object.keys(state)[0])]
export const CA_STATE_NAMES = ['', ...CA_states.map(state => Object.keys(state)[0])]
export const COUNTRIES_BY_NAME = reduceToNameCode(countries)
export const COUNTRIES_BY_CODE = reduceToCodeName(countries)
export const US_STATES_BY_NAME = reduceToNameCode([{ '': '' }, ...US_states])
export const US_STATES_BY_CODE = reduceToCodeName([{ '': '' }, ...US_states])
export const CA_STATES_BY_NAME = reduceToNameCode([{ '': '' }, ...CA_states])
export const CA_STATES_BY_CODE = reduceToCodeName([{ '': '' }, ...CA_states])
export const COUNTRY_STATES = {
  US: {
    byCode: US_STATES_BY_CODE,
    byName: US_STATES_BY_NAME,
    label: 'State',
    names: US_STATE_NAMES,
  },
  CA: {
    byCode: CA_STATES_BY_CODE,
    byName: CA_STATES_BY_NAME,
    label: 'Province',
    names: CA_STATE_NAMES,
  },
}
